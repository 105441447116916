export const NEW_ORDER_REQUEST = "NEW_ORDER_REQUEST";
export const NEW_ORDER_SUCCESS = "NEW_ORDER_SUCCESS";
export const NEW_ORDER_FAIL = "NEW_ORDER_FAIL";

export const MY_ORDERS_REQUEST = "MY_ORDERS_REQUEST";
export const MY_ORDERS_SUCCESS = "MY_ORDERS_SUCCESS";
export const MY_ORDERS_FAIL = "MY_ORDERS_FAIL";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ALL_ORDERS_REQUEST = "ALL_ORDERS_REQUEST";
export const ALL_ORDERS_SUCCESS = "ALL_ORDERS_SUCCESS";
export const ALL_ORDERS_FAIL = "ALL_ORDERS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
